import React from "react";
import ResourceCard from "../../resource-card/resource-card";

import "./resource-related.scss";

const RelatedResources = ({ relatedResources, title }) => {
  return (
    <>
      {relatedResources && (
        <section className="related-resources">
          <h3 className={`related-resources__title`}>{title}</h3>
          <ul className={`related-resources__list`}>
            {relatedResources.map((item, i) => {
              return (
                <li>
                  <ResourceCard key={i} {...item} />
                </li>
              );
            })}
          </ul>
        </section>
      )}
    </>
  );
};

export default RelatedResources;
