import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import ContentfulRichText from "../../components/contentful-richtext/contentful-richtext";
import Layout from "../../components/layout/layout";
import ResourceTypePill from "../../components/listings/resource-listing/resource-type-pill";
import RelatedResources from "../../components/listings/resource-listing/resource-related";
import { moduleMap } from "../../utils/moduleMap";
import Seo from "../../components/seo/seo";
import CtaButton from "../../components/cta-button/cta-button";
import ContactForm from "../../components/modules/contact-form/contact-form";

import "./resourcePage.scss";

export const query = graphql`
  query($id: String!) {
    contentfulResource(id: { eq: $id }) {
      id
      slug
      node_locale
      title
      seoMetaDescription
      gatedResource
      featuredImage {
        title
        description
        height
        width
        url
        gatsbyImageData
      }
      displayImage
      downloadableAsset {
        title
        description
        url
      }
      downloadableAssetCta
      content {
        raw
        references {
          ...RTEReferences
        }
      }
      type {
        ...TermFields
      }
      categories {
        ...TermFields
        ...SubTermFields
      }
      author {
        ...Author
      }
      relatedResources {
        ...Resource
      }
      downloadableAssetForm {
        ...Form
      }
    }
    resourceModules: contentfulPage(slug: { eq: "/resources/" }) {
      pageTheme: theme
      modules {
        __typename
        ... on Node {
          ...Modules
        }
      }
      createdAt
      updatedAt
    }
  }
`;

const ResourcePage = ({ data, pageContext }) => {
  const {
    title,
    featuredImage,
    displayImage,
    content,
    type,
    author,
    relatedResources,
    gatedResource,
    downloadableAsset,
    downloadableAssetCta,
    downloadableAssetForm,
  } = data.contentfulResource;

  // Use the modules below The resources filter on these pages.
  // If it is a Listing Module remove it
  // If it's a gated resource remove the module if it is a CTA with a Nwesletter
  // Sign up
  const resourceModules = data.resourceModules.modules.filter(module => {
    if (module.__typename !== "ContentfulContentListing") {
      if (
        gatedResource &&
        module.__typename === "ContentfulCallToAction" &&
        module.layout === "Newsletter Sign Up"
      ) {
        return null;
      } else {
        return module;
      }
    }
    return null;
  });

  // Map modules for placement in the page's primary content area
  const modules =
    resourceModules &&
    resourceModules.map((el, i) => {
      let Module = moduleMap[el.__typename];
      return <Module moduleId={`module-${i + 1}`} key={i} {...el} />;
    });

  const GenrateDownloadAsset = ({
    downloadableAsset,
    gatedResource,
    downloadableAssetForm,
    downloadableAssetCta,
  }) => {
    if (downloadableAsset && gatedResource && downloadableAssetForm) {
      // Replace the %NAME% placeholder with the asset title, for the thank you message
      const formThankYouMessage = downloadableAssetForm.formThankYouMessage.replace(
        "%NAME%",
        downloadableAsset.title
      );

      // Replace the %NAME% placeholder with the asset title, for the thank you message
      const formHeader = downloadableAssetForm.formHeader.replace(
        "%NAME%",
        downloadableAsset.title
      );
      return (
        <ContactForm
          {...downloadableAssetForm}
          formHeader={formHeader}
          formThankYouMessage={formThankYouMessage}
          callback={downloadableAsset}
          buttonCta={downloadableAssetCta}
          formThankYouButtonCta={downloadableAssetCta}
        />
      );
    }

    if (downloadableAsset && !gatedResource) {
      return (
        <CtaButton
          href={downloadableAsset.url}
          label={downloadableAssetCta || "Download Now"}
        />
      );
    }

    return null;
  };

  return (
    <Layout
      theme={data.resourceModules.pageTheme ?? "Blue"}
      type={pageContext.type}
    >
      <article className="resource-article">
        <header className="resource-article__header">
          <ResourceTypePill text={type[0].name} />
          <h2 className="resource-article__header__title">{title}</h2>
          {author && (
            <span className={`resource-article__header__author`}>
              by: {author.name}
            </span>
          )}
          {featuredImage && displayImage && (
            <div className="featured-image-container">
              <GatsbyImage
                className={`resource-article__featuredImageContainer`}
                imgClassName={`resource-article__featuredImage`}
                image={featuredImage.gatsbyImageData}
                alt={featuredImage.description}
                layout={"fullWidth"}
              />
            </div>
          )}
        </header>

        <section className="resource-article__content">
          {content && <ContentfulRichText richText={content} />}
          {downloadableAsset && (
            <GenrateDownloadAsset
              downloadableAsset={downloadableAsset}
              gatedResource={gatedResource}
              downloadableAssetForm={downloadableAssetForm}
              downloadableAssetCta={downloadableAssetCta}
            />
          )}
        </section>
        <RelatedResources
          relatedResources={relatedResources}
          title={`Related Articles`}
        />
      </article>
      {modules}
    </Layout>
  );
};

export const Head = ({
  data: {
    contentfulResource: {
      seoMetaDescription,
      createdAt,
      updatedAt,
      type,
      featuredImage,
      title,
    },
  },
  pageContext: { slug, locale },
}) => {
  const seoMeta = {
    slug,
    locale,
    createdAt,
    updatedAt,
    type: type[0].name,
    image: { ...featuredImage },
    metaTitle: title,
    metaDescription: seoMetaDescription,
  };
  return <Seo {...seoMeta} />;
};

export default ResourcePage;
